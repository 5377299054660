exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-driver-terms-of-service-js": () => import("./../../../src/pages/driver-terms-of-service.js" /* webpackChunkName: "component---src-pages-driver-terms-of-service-js" */),
  "component---src-pages-drivers-js": () => import("./../../../src/pages/drivers.js" /* webpackChunkName: "component---src-pages-drivers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resellers-js": () => import("./../../../src/pages/resellers.js" /* webpackChunkName: "component---src-pages-resellers-js" */),
  "component---src-pages-responsible-disclosures-js": () => import("./../../../src/pages/responsible-disclosures.js" /* webpackChunkName: "component---src-pages-responsible-disclosures-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-xaas-terms-of-service-js": () => import("./../../../src/pages/xaas-terms-of-service.js" /* webpackChunkName: "component---src-pages-xaas-terms-of-service-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "slice---src-components-common-footer-index-js": () => import("./../../../src/components/common/Footer/index.js" /* webpackChunkName: "slice---src-components-common-footer-index-js" */),
  "slice---src-components-common-header-index-js": () => import("./../../../src/components/common/Header/index.js" /* webpackChunkName: "slice---src-components-common-header-index-js" */),
  "slice---src-components-resources-resource-header-resources-header-js": () => import("./../../../src/components/resources/ResourceHeader/ResourcesHeader.js" /* webpackChunkName: "slice---src-components-resources-resource-header-resources-header-js" */)
}

